<template>
  <div>

    <!-- Alert: No item found -->
    <!-- <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert> -->

    <template v-if="workerData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <user-view-user-info-card :user-data="workerData" />
        </b-col>
        <!-- <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <user-view-user-plan-card />
        </b-col> -->
      </b-row>

      <!-- <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-timeline-card />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-permissions-card />
        </b-col>
      </b-row> -->

      <!-- <invoice-list /> -->

      <b-row>
        <b-col>
          <b-card no-body class="pt-2 px-2">
            <filters
              :processFilterShow="true"
              :dateRangeFilterShow="true"
              :dateRangeDaysLimit="365"
              @changeFilter="updateFilter"
            />
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="isLoading">
        <b-col>
          <b-card class="py-5">
            <loading
              :active="true" 
              :is-full-page="false"
              :color="colors.primary"
            />
          </b-card>
        </b-col>
      </b-row>

      <div v-else>
        <b-row v-if="!confirmations_total">
          <b-col>
            <b-card class="text-center">
              <h5 class="mb-0">
                {{ $t('message.no_confirmations_found') }}
              </h5>
            </b-card>
          </b-col>
        </b-row>

        <div v-else>
          <b-row>
            <b-col sm="3">
              <statistic-card-vertical-adherence :statistic="adherence_index" />
            </b-col>
            <b-col sm="3">
              <statistic-card-vertical-confirmations :statistic="confirmations_total" />
            </b-col>
            <b-col sm="3">
              <statistic-card-vertical-recognitions :statistic="recognitions_total" />
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <line-chart-adherence
                v-if="adherenceTimeSeries.labels && adherenceTimeSeries.labels.length"
                :adherence-data="adherenceTimeSeries"
              />
            </b-col>
          </b-row>

          <b-card>
            <b-table-simple hover small caption-top responsive>
              <b-thead head-variant="light">
                <b-tr>
                  <th>{{ $t('message.tableHeader.date') }}</th>
                  <th>{{ $t('message.tableHeader.observer') }}</th>
                  <th>{{ $t('message.tableHeader.process') }}</th>
                  <th>{{ $t('message.tableHeader.score') }}</th>
                  <th>{{ $t('message.tableHeader.total') }}</th>
                  <th>{{ $t('message.tableHeader.adherence') }}</th>
                  <th>{{ $t('message.tableHeader.action') }}</th>
                </b-tr>
                <b-tr v-for="(confirmation, index) in confirmations" :key="index">
                  <b-td>{{ confirmation.dateString }}</b-td>
                  <b-td>{{ confirmation.observer }}</b-td>
                  <b-td>{{ confirmation.process }}</b-td>
                  <b-td>{{ confirmation.score }}</b-td>
                  <b-td>{{ confirmation.total }}</b-td>
                  <b-td>{{ confirmation.adherenceText() }}</b-td>
                  <b-td>
                    <view-button @clicked="router.push({name: 'habit-confirmation-view', params: {id: confirmation.id}})" />
                  </b-td>
                </b-tr>
              </b-thead>
            </b-table-simple>
          </b-card>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BCard, BTableSimple, BThead, BTr, BTd
} from 'bootstrap-vue'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './UserViewUserPermissionsCard.vue'
import useUsersView from './useUsersView'
import StatisticCardVerticalAdherence from './chartComponents/StatisticCardVerticalAdherence.vue'
import StatisticCardVerticalConfirmations from './chartComponents/StatisticCardVerticalConfirmations.vue'
import StatisticCardVerticalRecognitions from './chartComponents/StatisticCardVerticalRecognitions.vue'
import Filters from "@/views/organization/Filters.vue"
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import LineChartAdherence from './chartComponents/ChartjsLineChartAdherence.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { colors } from '@/constants'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BCard,
    BTableSimple,
    BThead,
    BTr,
    BTd,

    // Local Components
    UserViewUserInfoCard,
    UserViewUserPlanCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
    StatisticCardVerticalAdherence,
    StatisticCardVerticalConfirmations,
    StatisticCardVerticalRecognitions,
    Filters,
    ViewButton,
    LineChartAdherence,
    Loading,

    // InvoiceList,
  },
  setup() {

    const {
      show, workerData, getConfirmations, confirmations, confirmations_total, adherence_index, getRecognitionsCount, recognitions_total, updateFilter, adherenceTimeSeries, isLoading
    } = useUsersView()

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    onMounted(() => {
      show()
      getConfirmations()
      getRecognitionsCount()
    })

    return {
      workerData,
      confirmations,
      confirmations_total,
      adherence_index,
      recognitions_total,
      updateFilter,
      router,
      adherenceTimeSeries,
      isLoading,
      colors
    }
  },
}
</script>

<style>

</style>
