<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          {{ $t('processAdherenceEvolution') }}
        </b-card-title>
        <!-- <b-card-sub-title>Commercial networks and enterprises</b-card-sub-title> -->
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <chartjs-component-line-chart
        :height="400"
        :data="this.series"
        :options="lineChart.options"
        :plugins="plugins"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardSubTitle, BCardTitle,
} from 'bootstrap-vue'
import ChartjsComponentLineChart from '@/views/habit/charts-components/ChartjsComponentLineChart.vue'
import { $themeColors } from '@themeConfig'
import useCommonDashboards from '@/views/habit/useCommonDashboards'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BCardTitle,
    ChartjsComponentLineChart,
  },
  props: {
    adherenceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      lineChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: 'label',
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: 'rgba(0, 0, 0, 0.25)',
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
            callbacks: {
              title: function(tooltipItems, data) {
                return data.datasets[tooltipItems[0].datasetIndex].data[tooltipItems[0].index].x
              },
              label: function(tooltipItem, data) {
                let label = data.datasets[tooltipItem.datasetIndex].label || ''
                if (label) label += ': '
                label += tooltipItem.yLabel + '%'
                return label
              }
            },
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
                ticks: {
                  fontColor: '#6e6b7b',
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 10,
                  min: 0,
                  max: 100,
                  fontColor: '#6e6b7b',
                  callback: function(value, index, values) {
                    return value + '%';
                  }
                },
                gridLines: {
                  display: true,
                  color: 'rgba(200, 200, 200, 0.2)',
                  zeroLineColor: 'rgba(200, 200, 200, 0.2)',
                },
              },
            ],
          },
          legend: {
            position: 'top',
            align: 'center',
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
        // data: {
        //   labels: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140],
        //   datasets: [
        //     {
        //       data: [80, 150, 180, 270, 210, 160, 160, 202, 265, 210, 270, 255, 290, 360, 375],
        //       label: 'Europe',
        //       borderColor: '#ff4961',
        //       lineTension: 0,
        //       pointStyle: 'circle',
        //       backgroundColor: '#ff4961',
        //       fill: false,
        //       pointRadius: 1,
        //       pointHoverRadius: 5,
        //       pointHoverBorderWidth: 5,
        //       pointBorderColor: 'transparent',
        //       pointHoverBorderColor: $themeColors.light,
        //       pointHoverBackgroundColor: '#ff4961',
        //       pointShadowOffsetX: 1,
        //       pointShadowOffsetY: 1,
        //       pointShadowBlur: 5,
        //       pointShadowColor: 'rgba(0, 0, 0, 0.25)',
        //     },
        //     {
        //       data: [80, 125, 105, 130, 215, 195, 140, 160, 230, 300, 220, 170, 210, 200, 280],
        //       label: 'Asia',
        //       borderColor: '#666ee8',
        //       lineTension: 0,
        //       pointStyle: 'circle',
        //       backgroundColor: '#666ee8',
        //       fill: false,
        //       pointRadius: 1,
        //       pointHoverRadius: 5,
        //       pointHoverBorderWidth: 5,
        //       pointBorderColor: 'transparent',
        //       pointHoverBorderColor: $themeColors.light,
        //       pointHoverBackgroundColor: '#666ee8',
        //       pointShadowOffsetX: 1,
        //       pointShadowOffsetY: 1,
        //       pointShadowBlur: 5,
        //       pointShadowColor: 'rgba(0, 0, 0, 0.25)',
        //     },
        //     {
        //       data: [80, 99, 82, 90, 115, 115, 74, 75, 130, 155, 125, 90, 140, 130, 180],
        //       label: 'Africa',
        //       borderColor: '#ffe802',
        //       lineTension: 0,
        //       pointStyle: 'circle',
        //       backgroundColor: '#ffe802',
        //       fill: false,
        //       pointRadius: 1,
        //       pointHoverRadius: 5,
        //       pointHoverBorderWidth: 5,
        //       pointBorderColor: 'transparent',
        //       pointHoverBorderColor: $themeColors.light,
        //       pointHoverBackgroundColor: '#ffe802',
        //       pointShadowOffsetX: 1,
        //       pointShadowOffsetY: 1,
        //       pointShadowBlur: 5,
        //       pointShadowColor: 'rgba(0, 0, 0, 0.25)',
        //     },
        //   ],
        // },
      },
      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20
            }
            /* eslint-enable */
          },
        },
      ],
    }
  },
  computed: {
    series() {
      const { colorPalette } = useCommonDashboards()
      let datasets = []
      let counter = 0
      for (const process in this.adherenceData.data) {
        const colorIndex = counter % colorPalette.length
        datasets.push({
          data: this.adherenceData.data[process],
          label: process,
          borderColor: colorPalette[colorIndex],
          lineTension: 0,
          pointStyle: 'circle',
          backgroundColor: colorPalette[colorIndex],
          fill: false,
          pointRadius: 5,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 5,
          pointBorderColor: 'transparent',
          pointHoverBorderColor: $themeColors.light,
          pointHoverBackgroundColor: colorPalette[colorIndex],
          pointShadowOffsetX: 1,
          pointShadowOffsetY: 1,
          pointShadowBlur: 5,
          pointShadowColor: 'rgba(0, 0, 0, 0.25)',
        })
        counter++
      }
      return {
        labels: this.adherenceData.labels,
        datasets: datasets,
      }
    },
  }
}
</script>
